<template>
  <div>
    <div class="flex justify-between mb-1" v-if="label">
      <label
        class="block text-sm font-medium text-gray-700 dark:text-gray-500 whitespace-nowrap"
        >{{ label }}
        <div class="FieldRequiredBadge" v-if="isRequired"></div>
      </label>
      <span
        class="text-xs text-lightgray-600 dark:text-gray-400"
        v-if="labelHelper"
        >{{ labelHelper }}</span
      >
    </div>

    <!-- Input Number -->
    <div class="inline-flex relative">
      <!-- Button -->
      <button
        class="px-3 text-lg"
        :class="
          disabled || readonly || !decreasable
            ? 'text-lightgray-300 cursor-not-allowed'
            : 'text-lightgray-900'
        "
        type="button"
        tabindex="-1"
        :disabled="disabled || readonly || !decreasable"
        @click="decrease"
      >
        -
      </button>

      <FormInput
        custom-class="min-w-[65px] max-w-[70px] text-center"
        ref="input"
        type="text"
        v-model="value"
        :size="inputSize"
        @keydown.enter="(e) => setValue(e.target.value)"
        :min="min"
        :max="max"
        :step="step"
        :readonly="readonly || !inputtable"
        :disabled="disabled || (!decreasable && !increasable)"
        :class="({ 'is-error': error }, [customClass], {'!text-gray-400 cursor-not-allowed' : disabled})"
        :placeholder="placeholder"
        autocomplete="off"
      />

      <span class="absolute top-full mt-0.5 left-1/2 -translate-x-1/2 text-2xs" v-if="showUnitTypeLabel">{{ unitTypeLabel }}</span>

      <!-- Button -->
      <button
        class="px-3 text-lg"
        :class="
          disabled || readonly || !increasable
            ? 'text-lightgray-300 cursor-not-allowed'
            : 'text-lightgray-900'
        "
        type="button"
        tabindex="-1"
        :disabled="disabled || readonly || !increasable"
        @click="increase"
      >
        +
      </button>
    </div>

    <p
      class="mt-2 text-xs text-lightgray-600 dark:text-gray-400"
      v-if="formHelper"
    >
      {{ formHelper }}
    </p>
    <p class="mt-2 text-sm text-danger-600" v-if="error">{{ error }}</p>
  </div>
</template>
<script setup>
const isNaN = Number.isNaN || window.isNaN
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/
const normalizeDecimalNumber = (value, times = 100000000000) => (
    REGEXP_DECIMALS.test(String(value)) ? (Math.round(value * times) / times) : value
)

const props = defineProps({
  modelValue: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number,
    default: Infinity,
  },
  min: {
    type: Number,
    default: -Infinity,
  },
  step: {
    type: Number,
    default: 1,
  },
  inputtable: {
    type: Boolean,
    default: true,
  },
  readonly: Boolean,
  rounded: Boolean,
  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  formHelper: {
    type: String,
    default: "",
  },
  labelHelper: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
  customClass: {
    type: String,
    default: null
  },
  buttonColor: {
    type: String,
    default: "lightblue"
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showUnitTypeLabel: {
    type: Boolean,
    default: false,
  },
  unitTypeLabel: {
    type: String,
    default: "Adet",
  },
  inputSize : {
    type : String,
    default : null
  },

})
const emit = defineEmits(['update:modelValue', 'change'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

/**
 * Indicate if the value is increasable.
 * @returns {boolean} Return `true` if it is decreasable, else `false`.
 */
const increasable = computed(() => isNaN(unref(value)) || unref(value) < props.max)

/**
 * Indicate if the value is decreasable.
 * @returns {boolean} Return `true` if it is decreasable, else `false`.
 */
const decreasable = computed(() => isNaN(unref(value)) || unref(value) > props.min)

/**
 * Decrease the value.
 */
const decrease = () => {
  if (decreasable.value) {
    if (isNaN(unref(value))) {
      value.value = 0;
    }
    setValue(normalizeDecimalNumber(unref(value) - props.step));
  }
}
/**
 * Increase the value.
 */
const increase = () => {
  if (increasable.value) {
    if (isNaN(unref(value))) {
      value.value = 0;
    }
    setValue(normalizeDecimalNumber(unref(value) + props.step));
  }
}

/**
 * Set new value and dispatch change event.
 * @param {number} value - The new value to set.
 */
const setValue = (val) => {
  const oldValue = val

  let newValue = typeof val !== 'number' ? parseFloat(val) : val

  if (!isNaN(newValue)) {
    if (props.min <= props.max) {
      newValue = Math.min(props.max, Math.max(props.min, newValue));
    }
    if (props.rounded) {
      newValue = Math.round(val);
    }
  }

  value.value = newValue

  // if (newValue === oldValue) {
  //   // Force to override the number in the input box (#13).
  //   (this.$refs.input).value = String(newValue);
  // }

  emit('change', newValue)
}

watch(() => props.modelValue, (newValue, oldValue) => {
  if (
      // Avoid triggering change event when created
      !(isNaN(newValue) && typeof oldValue === 'undefined')
      // Avoid infinite loop
      && newValue !== value.value
  ) {
    setValue(newValue);
  }
})

</script>
